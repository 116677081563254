import React, { Fragment } from "react"
import Meta from "../components/meta"
import firebase from "gatsby-plugin-firebase"
import LayoutIndex from "../components/layoutIndex"

export default function IndexPage() {
  const isNotLoggedin = () => {
    return (firebase.auth().currentUser === null)
  }

  return (
    <Fragment>
      <Meta title="Get Started" />
      <LayoutIndex>
        <div className="flex flex-col items-center">
          <div className="flex justify-center w-full px-8">
            <div className="grid place-items-center pt-10">
              <div className="pt-5 w-full max-w-md">
                <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2081 272" className="w-full">
                  <path fillRule="evenodd" fill="#383839" d="m469.6 26.9c5.1 2.3 10.4 7.5 13 12.7 1.4 2.8 1.9 6 1.9 11.4 0 8.6-1.8 13.2-7.3 18.8-5.2 5.1-10.5 7.2-18.7 7.2-8.1 0-13.4-2-18.3-7-5.6-5.6-7.6-10.5-7.6-19 0-5.7 0.5-8.5 2.2-11.6 2.8-5.5 7.7-10.3 12.7-12.5 5.5-2.5 16.6-2.5 22.1 0z" />
                  <path fillRule="evenodd" fill="#383839" d="m374.2 0c18.7 0 18.9 0 21.3 2.5l2.5 2.4v128.9c0 128.9 0 128.9-2.1 131.6l-2 2.6h-19.4c-18.7 0-19.5-0.1-21.5-2.2-2-2.2-2-2.7-2-131.7v-129.5l2.2-2.3c2.1-2.2 2.6-2.3 21-2.3z" />
                  <path fillRule="evenodd" fill="#383839" d="m279.5 114c32.3 10.1 51.4 36.9 50.3 70.4-0.5 16.1 5.4 14.5-54.7 14.8l-50.6 0.3 0.3 3c0.5 5.2 5.1 13.8 10 18.8 8.6 8.9 20.2 12.7 32.5 10.7 8.2-1.3 12.8-2.9 21.3-7.6 5.2-2.8 7.5-3.6 9.4-3 2.4 0.7 18.5 18.6 20.4 22.7 1.5 3.1-0.8 6.4-7.6 11.5-8.3 6.1-15.8 9.8-26.5 13.1-7.9 2.4-10.3 2.7-24.8 2.7-15.5 0-16.3-0.1-25.4-3.2-26.3-9.1-44-28.4-51.2-55.7-4.2-16.3-2.4-39.3 4.4-54.7 10.8-24.4 32-41 58.5-45.8 8-1.5 25.9-0.4 33.7 2zm-49.6 50.4c-3.7 8.1-5.5 7.6 26.6 7.6 32.3 0 30.4 0.6 26.6-8.4-2.4-5.6-9-12.2-14.5-14.6-14.5-6.4-31.9 0.5-38.7 15.4z" />
                  <path fillRule="evenodd" fill="#383839" d="m283.6 24c3.1 0 4.3 1.4 10.8 12.8 6.6 11.8 7.5 14.9 4.9 17.9-2.5 3-69.7 32.3-74 32.3-3.2 0-3.5-0.3-8-9.4-4-8-4.5-9.8-3.6-11.8 1-2.3 4.1-4.3 50.2-31.5 9.6-5.7 18.5-10.3 19.7-10.3z" />
                  <path fillRule="evenodd" fill="#383839" d="m134 0c17.7 0 19.1 0.1 21 2 2 2 2 3.3 2 132.4v130.5l-2.2 1.5c-1.7 1.2-4.8 1.6-12.4 1.6-11.8 0-12.2-0.3-15.4-9.6-1.1-3.2-2.2-6.5-2.5-7.2-0.4-1-2.1 0.1-5.7 3.3-25.3 22.7-63.3 23.2-89.9 1.2-12-10-21.5-25.4-26-42.2-3.1-11.5-3.1-31.6-0.1-43.7 11.4-46.2 55.6-69.7 101.5-54.1l5.7 2v-112.8l2.5-2.4c2.4-2.5 2.6-2.5 21.5-2.5zm-78.9 164.5c-6.4 7.1-9.3 14.4-9.8 25.5-0.4 6.9-0.1 9.7 1.7 14.9 5.5 16.4 17.4 25.6 33 25.5 12.9-0.1 23.5-7 29.1-18.9l2.9-6v-44.5l-4.8-2.4c-2.6-1.3-7.2-3.1-10.2-3.9-17-4.7-31.7-1.3-41.9 9.8z" />
                  <path fillRule="evenodd" fill="#383839" d="m1140.5 113.6c2.8 0.9 6.2 2.6 7.7 3.6 5 3.5 4.7 5.6-2.7 19.1-11 19.7-11.6 20.3-20.5 17.7-10.5-3.2-23.5 0.5-31 8.6l-4 4.4v47.5c0 47.5-0.2 50.6-3.4 52.7-0.6 0.4-9.4 0.8-19.5 0.8-17.6 0-18.6-0.1-20.8-2.2l-2.3-2.1v-72.1-72l2.2-2.3c2-2.1 3-2.3 12.3-2.3 6.5 0 10.7 0.4 11.8 1.2 1.3 1.1 3.8 6.7 7.2 16.6 0.3 0.7 3.2-1.5 7.2-5.6 7.3-7.4 17.7-13.4 26.3-15.2 6.5-1.3 23.6-0.4 29.5 1.6z" />
                  <path fillRule="evenodd" fill="#383839" d="m1019.5 174.3c1.3 6 1.6 10.9 1.3 20.5-0.4 11-0.9 14-3.7 22.2-8.6 25.5-27.8 44.2-53.1 52.1-6.3 2-9.7 2.4-21 2.4-11.4 0-14.7-0.4-21-2.3-22.6-7.1-39.4-21.6-49.4-42.5-6.3-13.2-8-20.7-7.9-35.7 0-14.8 2-23.5 8.2-35.8 11.7-23.2 32.8-38.9 57.9-43.2 40-6.7 79.6 21.1 88.7 62.3zm-93.6-17.1c-7.4 3.9-12.2 9.1-16.3 17.7-2.8 6-3.1 7.5-3 16.1 0 11.2 2.3 18.2 8.4 25.8 7.3 9.3 20.5 15.1 31.3 13.7 20.8-2.6 36.5-24.6 32.7-45.7-2.4-13.1-12.1-25.5-23.3-29.6-10.4-3.9-20.1-3.2-29.8 2z" />
                  <path fillRule="evenodd" fill="#383839" d="m800.3 115.5c22.3 7.7 41.2 27.3 49.3 51 3.9 11.3 4.6 31 1.6 43.6-3.9 16.3-11.3 29-23.5 40.5-16.3 15.3-34.3 22.1-56.2 21.1-34.8-1.6-63.2-25.2-72.6-60.2-2.8-10.4-3-28.9-0.6-38.4 4.1-15.2 10-26 20.2-36.9 12.7-13.6 28.8-22.1 46-24.5 11.1-1.5 24.6-0.1 35.8 3.8zm-42.8 41.9c-23.4 12.8-25.9 48.3-4.5 65.2 14.5 11.5 33.3 10.4 46.7-2.8 9.1-9 13.7-23.2 11.3-35.1-0.6-3.4-2.3-8.5-3.6-11.3-3.3-7-12.4-15.5-19.4-18.1-10.9-4-20.6-3.4-30.5 2.1z" />
                  <path fillRule="evenodd" fill="#383839" d="m650 0c18.9 0 19.1 0 21.5 2.5l2.5 2.4v128.9c0 152 2 134.2-15 134.2-12.6 0-12.5 0.1-16.1-11-1.2-3.6-2.3-6.7-2.5-6.9-0.2-0.2-3.1 2.2-6.5 5.2-12.7 11.5-29.4 17.3-47.4 16.4-33.9-1.7-62.3-28.2-68.6-64.1-1.7-10.2-0.7-32.2 2.1-41.6 4.3-14.9 14-30 24.8-38.5 7-5.6 19.5-11.9 28.2-14.2 9.9-2.6 31-2.3 41.5 0.7 4.4 1.2 9 2.4 10.3 2.7l2.2 0.5v-56.6c0-55.3 0-56.6 2-58.6 1.9-1.9 3.3-2 21-2zm-69.2 157.2c-22.3 11.9-26.6 45.1-8.2 63.5 16.3 16.3 43.3 11.6 53-9.2 2.3-4.8 2.4-6 2.4-27.7v-22.8l-5.7-2.8c-3.2-1.6-8-3.4-10.8-4-12.5-2.7-21.7-1.8-30.7 3z" />
                  <path fillRule="evenodd" fill="#383839" d="m458.7 115c17.8 0 18.8 0.1 21 2.2l2.3 2.1v72.2c0 71.2 0 72.2-2 74.3-2 2.1-2.8 2.2-21 2.2-17.7 0-19.1-0.1-21-2-2-2-2-3.3-2-74.5 0-71.2 0-72.5 2-74.5 1.9-1.9 3.3-2 20.7-2z" />
                  <path fillRule="evenodd" fill="#a3ca81" d="m1987 80h22 22v19 19h19.5 19.5v17 17h-19.5-19.5v32.5c0 19.6 0.4 34.4 1.1 37.5 1.2 5.6 4.5 10.2 9.3 12.7 4.7 2.5 15.8 2.2 22.4-0.7 2.9-1.2 5.4-2.1 5.6-1.9 0.2 0.2 2.9 7.1 6 15.3 4.6 11.8 5.5 15.1 4.4 15.8-15.7 10.6-47 11.7-66.3 2.3-11.4-5.5-18.8-14-23.7-27.1-2.1-5.7-2.2-7.5-2.6-46.2l-0.3-40.2h-12-11.9v-17-17h12 12v-19z" />
                  <path fillRule="evenodd" fill="#a3ca81" d="m1943.3 112.1l4.7-0.1v20.5 20.5h-9.2c-18.8 0-30.6 6.3-37.1 19.5-4.3 8.7-4.7 13.2-4.7 56.5v41h-22.5-22.5v-78-78h21.5 21.5v10.2 10.2l5.3-5.1c8.8-8.6 19.8-14 32.9-16.2 2.9-0.5 7.4-0.9 10.1-1z" />
                  <path fillRule="evenodd" fill="#a3ca81" d="m1762.5 114.9c28.7 7.6 44.1 25.3 47.5 54.6 0.5 4.9 1 29.6 1 54.7v45.8h-42v-19.4l-4.9 5.5c-5.4 6-13.1 10.6-22.6 13.6-4.7 1.4-9.2 1.8-21 1.7-13.5 0-15.8-0.3-23.1-2.7-23-7.6-35.6-24.9-34.1-46.7 0.9-13.7 7.1-24.8 17.8-31.9 13.5-9 24.7-11.1 59.7-11.1h25.5l-0.7-5.4c-1.7-15.1-10.6-23.2-28.1-25.7-14.3-1.9-30.8 1.4-45.4 9.2-4 2.2-7.5 3.8-7.6 3.7-0.1-0.2-3.6-7-7.9-15.2-8.8-17.1-9.2-15 3.9-21.5 9.1-4.6 22.2-8.6 34.5-10.6 12.4-2.1 37-1.3 47.5 1.4zm3.5 89.1l-15.7 0.1c-26.9 0.3-33.6 1.6-39.4 7.5-3.3 3.4-3.4 3.8-3.4 11.4 0 7.4 0.2 8.1 3 11 5.8 6.2 9.8 7.5 22 7.5 9.1 0 11.8-0.4 15.7-2.2 7.2-3.3 11.7-7.1 14.9-12.7 2.6-4.6 2.9-6 2.9-13.9z" />
                  <path fillRule="evenodd" fill="#a3ca81" d="m1475.3 113.6c11.9 2.6 21.1 7.6 29.2 15.9l7.1 7.2 6.8-6.4c7.5-6.9 19-13.2 29.1-15.9 3.3-0.8 10.7-1.8 16.5-2.1 29.7-1.6 52.9 12 61.9 36.2 4.8 12.7 5.1 17.4 5.1 71.1v50.4h-21.9-21.9l-0.4-46.8c-0.4-39.5-0.7-47.5-2.1-51.7-2.6-7.9-7-14-12.4-17.2-4.5-2.6-5.5-2.8-15.8-2.8-9.4 0-11.7 0.3-15.6 2.3-9.3 4.5-15.7 13.6-17.9 25.2-0.6 3-1 23.5-1 48.1v42.9h-22.5-22.5v-45.8c0-50-0.3-53.2-5.8-62.2-1.6-2.6-4.8-5.7-7.6-7.4-4.3-2.7-5.9-3.1-14.5-3.4-7.9-0.3-10.6 0-15.1 1.7-10.2 3.8-17.6 13.5-20 26.1-0.6 3-1 23.5-1 48.1v42.9h-22.5-22.5v-78-78h21.5 21.5v9.1 9.1l5.3-4.8c6.2-5.6 18.8-11.9 27.7-13.8 8.8-1.9 22.6-1.9 31.3 0z" />
                  <path fillRule="evenodd" fill="#a3ca81" d="m1324.1 120.6c11.4 4.3 11.2 2.8 2.9 20.6l-7.2 15.6-6.4-2.9c-13-5.9-20.4-7.4-37.4-7.4-17.5 0-23 1.3-27.5 6.6-2.7 3.3-3.4 9.4-1.5 13 2.3 4.3 7.1 5.6 42.4 12.4 18.2 3.5 25 5.7 32.2 10.3 11.3 7.3 16.8 16.4 18 30 2.1 22.3-14 41.8-40.5 49.1-28 7.8-67.9 3.8-93.6-9.2l-6.4-3.2 7-15c3.9-8.3 7.4-15.3 7.8-15.8 0.4-0.4 2.7 0.3 5.2 1.7 6.4 3.5 20.5 8.3 29.9 10 10.9 2.1 29 2.1 36 0 7.2-2.1 12-6.9 12-11.9 0-2-0.5-4.5-1-5.6-2.4-4.3-10.4-6.7-36.9-10.9-24.4-3.9-36.2-8.4-45.3-17.2-7-6.8-10-13.9-10.6-25.4-0.4-7.4-0.1-10.5 1.6-16.1 5.8-19.1 24.7-32.2 52-36.2 19.8-2.9 48.3 0.2 67.3 7.5z" />
                </svg>
              </div>
              <div className="text-xl my-10 text-center">Delicious eating made easy</div>
              <img src="/images/meal.webp" alt="meal"></img>
              <div className="text-xl my-10 text-center">Delicious meals delivered frozen for locked in freshness and taste</div>
              {!isNotLoggedin ? null :
                <div className="my-10 mb-10">
                  <a
                    className="cursor-pointer inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 focus:outline-none text-black bg-delidoor-light hover:bg-delidoor-dark"
                    href="/getstarted"
                  >
                    <div className="text-xl text-center font-medium">Set up your Delidoor smart</div>
                  </a>
                </div>
              }
            </div>
          </div>
        </div>
      </LayoutIndex>
    </Fragment>
  )
}