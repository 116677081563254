import React from "react"

export default function LayoutIndex({ children }) {
    return (
        <div className="h-screen">
            <div className="flex flex-col justify-between bg-white w-full h-full min-h-full">
                <div className="flex flex-col">
                    <div className="p-8 md:justify-end justify-center flex flex-wrap items-center no-mobile-outline">
                        <div className="text-xs text-center m-2">Already have an account?</div>
                        <a
                            className="cursor-pointer ml-1 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 focus:outline-none text-black bg-delidoor-light hover:bg-delidoor-dark"
                            href="/account"
                        >
                            <div className="text-xs font-medium text-center">Go to my account</div>
                        </a>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="flex justify-center w-full px-8">
                            {children}
                        </div>
                    </div>
                </div>
                <div className="flex flex-nowrap justify-center px-4 pb-4 pt-12">
                    <div className="text-xs">Délidoor © {new Date().getFullYear()}</div>
                    <div className="text-xs mx-2">|</div>
                    <div className="text-xs"><a href="https://delidoor.com.au/policies/privacy-policy" className="underline">Privacy</a></div>
                    <div className="text-xs mx-2">|</div>
                    <div className="text-xs"><a href="https://delidoor.com.au/policies/terms-of-service" className="underline" target="_blank" rel="noopener noreferrer">Terms</a></div>
                </div>
            </div>
        </div>
    )
}